// TODO: Move non-theme color code into component specific SCSS
// Strategy: search for each class (other than theme colors) and 
// move the code over to a scss in the same dir as the component file.
// TODO: Move colors into variable names for re-use and consistency.
// TODO: Can better leverage scss nesting in various classes-- consider BEM methodology

// *********************
// THEME COLORS
// *********************
$theme-colors: (
  "info": tomato,
  "danger": teal,
);

$themeDarkBackground: #1d1d1d;
$themeDarkContainer: #252525;
$themeDarkText: #fff;

$themeLightBackground: #efefef;
$themeLightText: #151515;

$themeBlue: #3d93b9;
$themeGreen: #32efa6;
$themeLavendarBlue: #40a9ff;
$themeLightOrange: #ffe6a2;
$themeOrange: #fcb045;
$themePurple: #813db4;
$themeDarkPurple: #59297d;
$themeRed: #e63e3e;
$themeTurquoise: #00e6ff;
$themeVibrantYellowGreen: #f4fd5b;
$themeVibrantYellowGreenLight: #f6ff98;

$navLightHover: #e6e6e6;
$turquoiseLink: #00e6ff;

:root {
  --theme-green: #{$themeGreen};
  --theme-orange: #{$themeOrange};
  --theme-purple: #{$themePurple};
  --theme-darkPurple: #{$themeDarkPurple};
  --theme-red: #{$themeRed};
  --theme-turquoise: #{$themeTurquoise};
  --theme-vibrant-yellow-green: #{$themeVibrantYellowGreen};
}

// *********************
// HTML & GENERAL STYLINGS
// *********************
body {
  background-color: $themeDarkBackground !important;
  color: $themeDarkText !important;
  line-height: 1.7em !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 4.1em !important;
}

h2 {
  font-size: 3.1em !important;
}

h3 {
  font-size: 2.2em !important;
}

h4 {
  font-size: 1.7em !important;
}

h5 {
  font-size: 1.2em !important;
}

h6 {
  font-size: 1.1em !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 1rem;
  margin-top: 0.5rem !important;
  font-family: "Bungee", cursive;
  width: 100%;
  text-shadow: 2px 2px 4px black;
}

p {
  font-family: "Raleway", sans-serif;
  padding: 1rem;
  margin-bottom: 0rem !important;
  font-size: 22px;
  line-height: 1.5em;
}

code {
  font-family: "Roboto Mono", monospace;
}

.mainContent {
  .row {
    a {
      color: $turquoiseLink;
    }

    a:hover,
    a:active,
    a:visited {
      color: $themeVibrantYellowGreen;
    }
  }
}

.link-light-bg {
  a {
    color: $themeBlue !important;
  }

  a:hover,
  a:active,
  a:visited {
    color: $themeRed !important;
  }
}

.highlighter {
  background-color: #d8e052;
  padding: 1px 5px 1px 5px;
  border-radius: 0.25em;
}

// make links inside a row bold
.row a {
  font-weight: 800 !important;
}

.container-fluid a {
  font-weight: 600 !important;
}

// add margin spacing to top and bottom row
.row {
  max-width: 2000px; // prevent width from going full on large displays
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 50px 25px !important;
}

// Reduce row padding for smaller mobile displays
@media (max-width: 450px) {
  .row {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.light-text-shadow,
.light-text-shadow * {
  text-shadow: 2px 2px 4px white;
}

.row-slim-padding {
  padding: 13px 0px 0px 0px !important;
}

.icon-shadow-light-bg {
  filter: drop-shadow(2px 2px 2px #828900) !important;
}

@media (max-width: 536px) {
  h1 {
    font-size: 3em !important;
  }

  h2 {
    font-size: 2.4em !important;
  }

  h3 {
    font-size: 1.9em !important;
  }

  h4 {
    font-size: 1.7em !important;
  }

  h5 {
    font-size: 1.2em !important;
  }

  h6 {
    font-size: 1.1em !important;
  }
}

@media (max-width: 450px) {
  h1 {
    font-size: 2.46em !important;
  }

  h2 {
    font-size: 2.2em !important;
  }
}

@media (max-width: 360px) {
  h1 {
    font-size: 2.2em !important;
  }

  h2 {
    font-size: 2em !important;
  }
}

// *********************
// NAVIGATION AND NAVIGATION DROP DOWN MENUS
// *********************
.timknab-logo {
  height: 50px;
  filter: drop-shadow(2px 2px 2px black) !important;
}

.custom-nav-toggle {
  font-size: 1.5em;
  color: #f4fd5b;
}

.custom-nav-toggle:active {
  font-size: 1.5em;
  color: #fff;
}

.nav-link {
  font-size: 2rem;
  font-weight: bolder;
  text-shadow: 2px 2px 4px black;
  font-family: "Bungee", cursive;
}

.navbar-brand {
  padding-left: 8px;
  padding-right: 8px;

  &:hover {
    @extend .shimmer
  }
}

.shimmer:hover {
  background: linear-gradient(41deg, #813db4, #32efa6, #fcb045);
  background-size: 600% 600%;
  border-radius: 15px;
  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  -o-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
  cursor: pointer;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 96%
  }

  50% {
    background-position: 100% 5%
  }

  100% {
    background-position: 0% 96%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 96%
  }

  50% {
    background-position: 100% 5%
  }

  100% {
    background-position: 0% 96%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 96%
  }

  50% {
    background-position: 100% 5%
  }

  100% {
    background-position: 0% 96%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 96%
  }

  50% {
    background-position: 100% 5%
  }

  100% {
    background-position: 0% 96%
  }
}

// customize font awesome icon for nav
// makes icon more centered and aligned with text
// .primary-nav-icon {
//   margin: 6px;
//   bottom: 3px;
//   position: relative;
// }

// add hover to font awesome icon on link hover
// learn more: https://stackoverflow.com/questions/6966180/change-element-style-on-hover-another-element
// .resume-cta div a:hover > svg {
//   filter: drop-shadow(2px 2px 2px black) !important;
// }

// Adjust line height for primary navigation items
.navbar-collapse {
  line-height: 2.1em;
}

// Change hamburger menu background color
.navbar-dark .navbar-toggler {
  background-color: $themePurple;
  border: unset !important;
  padding: 10px;
}

.navbar-toggler {
  box-shadow: 2px 2px 2px #8500ff61;
}

.navbar-toggler:active {
  border: unset !important;
}

// Add drop shadow to nav "About" dropdown arrow
.dropdown-toggle::after {
  filter: drop-shadow(2px 2px 2px black) !important;
}

// Center adjust "About" drop down menu heading for mobile
@media (max-width: 992px) {
  .navbar-collapse {
    margin-bottom: 20px;
  }

  .nav-dropdown-adjustment {
    margin-left: 31px;
  }

  #collasible-nav-dropdown-about {
    position: relative;
    right: 10px;
  }
}

.dropdown-item {
  font-family: "Raleway", cursive;
  font-size: 1.5em;
  text-align: center !important;
  padding: 10px 10px !important;
}

.dropdown-menu.show {
  background-clip: padding-box;
  background-color: whitesmoke;
  border: 2px solid rgba(0, 0, 0, 0.15);
  font-size: 1.2rem;
  min-width: 300px;
  border-radius: 10px;
}

@media (max-width: 450px) {
  #responsive-navbar-nav {
    width: 100%;
  }
}

.navbar.navbar-expand-lg.navbar-dark.bg-primary {
  background: $themePurple;
  background: linear-gradient(90deg,
      $themePurple 0%,
      $themeGreen 53%);
  color: #b6b6b6;
}

.navbar-dark .navbar-nav .nav-link {
  color: $themeLightBackground !important;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:focus {
  color: $themeVibrantYellowGreenLight !important;
}

// Set hover and active styles for nav dropdown
.theme-twk-custom-nav-dropdown>.dropdown-menu>a:hover,
.dropdown-item.active,
.dropdown-item:active,
a.dropdown-item.active {
  background: $themeLavendarBlue !important;
  color: white !important;
  text-shadow: 2px 2px 4px black;
}

// Add line height to dropdown nav
navbar-nav .dropdown-menu {
  line-height: 1.5em;
}

// Set card margins and ensure last paragraph has no padding
.card {
  margin: auto 15px;
  color: $themeDarkBackground;
  background-color: $themeVibrantYellowGreen !important;
  border: 1px solid $themeVibrantYellowGreen !important;
}

.card p:last-child {
  margin-bottom: 0px;
}

// *********************
// PROFESSIONAL SUMMARY
// *********************
.professional-summary {
  background-color: $themeVibrantYellowGreen;
  background-image: url("./assets/images/axiom-bg-pattern.png");
  color: $themeDarkBackground;
  border-top: 20px solid #d8e052;
  border-bottom: 20px solid #c2c76b;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.professional-summary-description {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.bio-photo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 10px 3px 14px 5px #989e21;
  max-height: 500px;
}

.socials {
  text-align: center;
  max-width: 800px;

  svg:hover {
    @extend .shimmer
  }
}

.social-linkedin,
.social-linkedin:visited {
  color: #0e6aa8 !important;
}

.social-github,
.social-github:visited {
  color: #2a2a2a !important;
}

.social-stackoverflow,
.social-stackoverflow:visited {
  color: #f2770c !important;
}

.social-linkedin:hover,
.social-github:hover,
.social-stackoverflow:hover {
  color: #454545 !important;
}

@media (max-width: 991px) {
  .bio-photo {
    box-shadow: 1px 12px 14px 5px #989e21;
  }

  .professional-summary-description {
    h1 {
      margin-top: 20px !important;
    }
  }
}

// *********************
// ABOUT ME
// *********************

.about-me {
  padding-bottom: 65px !important;
  margin-bottom: -15px;
}

// *********************
// SKILLS
// *********************

.skills {
  border-top: 20px solid #fc4d45; // #fcb045
  border-bottom: 20px solid #c2c76b;
  background-color: #a99b87; // #958a7a;// #2b2b2b
  background-image: url("./assets/images/black-orchid-bg-pattern.png");
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.skills-wrapper {
  margin-bottom: 25px;
}

.skills h4 {
  padding-bottom: 0;
}

.skills ul {
  list-style-type: none;
  margin-bottom: 0px;
}

.skills li {
  text-shadow: 2px 2px 4px #000000;
  font-size: 22px;
}

.skills li::before {
  content: "•";
  display: inline-block;
  text-shadow: 2px 2px 4px #000000;
}

.stacks {
  background-color: $themeVibrantYellowGreenLight;
  border-bottom: 20px solid #c2c76b;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-image: url("./assets/images/axiom-bg-pattern.png");
}

.tilt {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tilt:hover {
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.morph {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.morph:hover {
  border-radius: 50%;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.stack-image {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ror-image {
  bottom: 19px;
}

.hack-image {
  bottom: 6px;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-grow: 0.6 !important;
}

.lamp-image {
  bottom: 20px;
}

@media (max-width: 991px) {
  .stack-image-adjust {
    bottom: 0px;
  }

  .hack-image {
    margin-bottom: -35px;
    margin-right: auto;
    bottom: 17px;
  }
}

// *********************
// PROJECTS
// *********************
.projects {
  background-color: #1d1d1d; // rgb(33, 33, 33)
  padding-bottom: 65px !important;
  margin-bottom: -15px;
}

.projects h3 {
  padding-bottom: 0;
}

.projects p {
  padding-top: 0;
  text-shadow: 2px 2px 4px #000000;
}

.project-row {
  padding-left: 0px !important;
  padding-right: 0px !important;

  .btn,
  .btn:visited {
    margin: 1rem;
    background-color: #382726;
    border-color: #fc4d45;
    border: 2px solid #fc4d45;
    color: #ffffff !important;

    &:hover {
      background-color: #fc4d45;
    }
  }
}

.project-col {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;

  &:hover {
    padding-top: 30px;
    padding-bottom: 30px;
    @extend .shimmer
  }
}

@media (max-width: 767px) {
  .project-col {
    margin-bottom: 30px;
  }
}

.project-icon {
  width: 150px;
  height: 150px;
}

// *********************
// EXPERIENCE
// *********************

.experience {
  border-top: 20px solid #fc4d45;
  background-color: #a279c1;
  background-image: url("./assets/images/black-orchid-bg-pattern.png");
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.experience h3 {
  padding-bottom: 0;
}

.experience p {
  padding-top: 0;
  text-shadow: 2px 2px 4px #000000;
}

.experience-block {
  border-left: 20px solid $themeTurquoise;
  margin-left: 25px;
}

.experience-block:first-child {
  border-top-left-radius: 15px;
}

.experience-block:last-child {
  border-bottom-left-radius: 15px;
  margin-bottom: 35px;
}

.experience-year-heading {
  font-size: 25px;
  vertical-align: middle;
}

.experience-description-wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.experience-description {
  // margin-top: 50px;
  margin-right: 25px;
  border-right: 20px solid $themeTurquoise;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  vertical-align: middle;
  background-color: $themeDarkContainer;
  padding: 35px !important;
  border-radius: 25px;
  box-shadow: -10px 10px 10px rgba($themeDarkBackground, 0.6);

  em {
    margin-left: 6px;
  }
}

// *********************
// EDUCATION HISTORY
// *********************
.education-history {
  background-color: $themeVibrantYellowGreen;
  color: $themeDarkBackground;
  background-image: url("./assets/images/axiom-bg-pattern.png");
  border-top: 20px solid #d2d740;
  border-bottom: 20px solid #d2d740;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 1px 22px 33px -16px #000000bf;
}

.education-history h4 {
  padding-bottom: 0;
}

.education-history h5 {
  padding-bottom: 0;
  padding-top: 0;
  font-style: italic;
}

.education-history p {
  padding-top: 0;
}

// *********************
// AWARDS
// *********************
.awards {
  margin-bottom: 25px;
}

// *********************
// FOOTER
// *********************
.footer {
  border-top: 20px solid #7fddb9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: $themeGreen;
  background: linear-gradient(90deg,
      $themeGreen 0%,
      $themePurple 92%);
}

.handcrafted-tag {
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  text-align: center;
  margin-bottom: 0px;
}

.wa-state img {
  max-height: 65px;
  filter: drop-shadow(2px 2px 2px #000000) !important;
  margin-right: 10px;
}

.crafted-with-care {
  border-radius: 0.9rem;
  font-style: italic;
  font-size: 16px;
}

.love-icon {
  margin: 0px 4px;
  font-style: normal !important;
  font-size: 35px;
  position: relative;
  top: 8px;
}

.tagline {
  padding-bottom: 0px !important;
}

.copyright {
  padding: 0px 15px !important;
}

.copyright-text p {
  font-size: 13px;
}

.copyright-text a {
  color: white;
  font-weight: 400 !important;
}

.copyright-text a:visited {
  color: white;
}

.copyright-text a:hover {
  color: $themeVibrantYellowGreen;
  font-weight: bold !important;
  text-decoration: none;
}

@media (max-width: 991px) {
  .copyright-text {
    text-align: center;
  }

  .handcrafted-tag {
    position: relative;
    right: 10px;
  }

  .crafted-with-care {
    width: 100%;
    display: block;
  }

  .experience-description {
    margin-right: 0px;
    margin: 20px 0px 20px;
  }
}

// *********************
// IMPORTS
// *********************
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Roboto+Mono:ital@0;1&family=Roboto:ital,wght@0,400;0,900;1,400;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");